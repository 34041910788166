export default {
  computed: {
    districtCustomItems () {
      return this.$store.getters['serviceData/optionsList']('building.district_custom', false)
    },
    districtOfficialItems () {
      return this.$store.getters['serviceData/optionsList']('building.district_official', false)
    },
    districtCustomItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.district_custom')
    },
    districtOfficialItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.district_official')
    },
    buildingMaterialItems () {
      return this.$store.getters['serviceData/optionsList']('building.building_material', false)
    },
    buildingMaterialItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.building_material')
    },
    buildingMaterialItemsWithEmptyAll () {
      return this.$store.getters['serviceData/optionsList']('building.building_material', false, true)
    },
    builderItems () {
      return this.$store.getters['serviceData/optionsList']('building.builder', false)
    },
    builderItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.builder')
    },
    buildingTypeItems () {
      return this.$store.getters['serviceData/optionsList']('building.building_type')
    },
    apartmentComplexItems () {
      return this.$store.getters['serviceData/optionsList']('building.apartment_complex', false)
    },
    apartmentComplexItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.apartment_complex')
    },
    managerCompanyItems () {
      return this.$store.getters['serviceData/optionsList']('building.manager_company')
    },
    buildingNumbers () {
      return this.buildingNumberList
    },
    buildingNumbersWithEmpty () {
      this.buildingNumberList.unshift({ id: null, building_number: 'Не указано' })
      return this.buildingNumberList
    },
    subdistrictItems () {
      return this.$store.getters['serviceData/optionsList']('building.subdistrict')
    },
    flatTypeItems () {
      return this.$store.getters['serviceData/optionsList']('advert.flat_type', false)
    },
    flatTypeItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('advert.flat_type')
    },
    kitchenTypeItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('advert.kitchen_type')
    },
    kitchenTypeItems () {
      return this.$store.getters['serviceData/optionsList']('advert.kitchen_type', false)
    },
    flatStatusItems () {
      return this.$store.getters['serviceData/optionsList']('advert.flat_status', false)
    },
    flatStatusItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('advert.flat_status')
    },
    buildOverlapItems () {
      return this.$store.getters['serviceData/optionsList']('building.build_overlap', false)
    },
    impressionItems () {
      return this.$store.getters['serviceData/optionsList']('user.impression')
    },
    successRateItems () {
      return this.$store.getters['serviceData/optionsList']('user.success_rate')
    },
    dealStepItems () {
      return this.$store.getters['serviceData/optionsList']('user.deal_step')
    },
    paymentMethodItems () {
      return this.$store.getters['serviceData/optionsList']('user.payment_method')
    },
    ownerStatuses () {
      return this.$store.getters['serviceData/optionsList']('phone.owner_status', false)
    },
    ownerStatusesWithEmpty () {
      let result = this.$store.getters['serviceData/optionsList']('phone.owner_status', false, false)
      if (result) {
        result = [{ id: null, value: 'Все' }, { id: 0, value: 'Не указано' }].concat(result)
      }
      return result
    }
  }
}
