import tabActions from '@/components/mixins/tabActions'
import StructureFactory from '@/components/structures/structureFactory'

export default {
  mixins: [tabActions],
  props: {
    value: {}
  },
  data () {
    return {
      detail: this.value.model,
      localState: null,
      loading: true,
      localRequestParams: this.value.requestParams,
      canDelete: true,
      checkingDelete: false,
      confirmDelete: false,
      isSavingData: false,
      forceFillDetail: false
    }
  },
  watch: {
    'localRequestParams.id': {
      handler: function () {
        this.$emit('change', {
          requestParams: this.localRequestParams
        })
      },
      deep: true
    },
    detail: {
      handler: function () {
        this.$emit('change', {
          model: this.detail
        })
      },
      deep: true
    },
    state: {
      handler: function () {
        this.$emit('change', {
          state: this.localState
        })
      },
      deep: true
    }
  },
  computed: {
    detailStructure () {
      return new StructureFactory(this.componentCategory)
    },
    isLoading () {
      return this.loading
    }
  },
  methods: {
    hasErrors () {
      return false
    },
    handleInput (data) {
      this.detail = { ...this.detail, ...data }
    },
    async handleSafeDelete () {
      await this.checkSafeDelete()
      if (this.canDelete !== null) {
        this.confirmDelete = true
      }
    },
    async fillDetails () {
      if (this.detail && !this.forceFillDetail) {
        // если есть сохраненная модель, не обновляем данные
      } else if (this.value.requestParams.id) {
        const response = await this.getDetails()
        if (response) {
          this.detail = response
        }
      } else {
        this.detail = this.detailStructure
      }
      this.loading = false
    },
    async getDetails () {
      return this.$store.dispatch('detail/fillDetail', {
        url: '/' + this.componentCategory + '/detail/' + this.value.requestParams.id
      })
    },
    async cancel () {
      if (this.value.requestParams.id) {
        const response = await this.getDetails()
        if (response) {
          this.detail = response
        }
      }
    },
    async saveDetail (saveAs = true) {
      this.isSavingData = true
      const errors = await this.hasErrors()
      if (errors) {
        this.$store.commit('systemMessages/addError', errors)
      } else {
        let payload = {
          url: '/' + this.componentCategory + '/detail',
          data: { ...this.detail }
        }

        if (saveAs) {
          payload.data.updated_at = null
        }

        const response = await this.$store.dispatch('detail/save', payload)
        if (response) {
          this.detail = response
          this.value.requestParams.id = this.detail.id
        }
      }
      this.isSavingData = false
      return true
    },
    async deleteDetail () {
      this.checkingDelete = true
      const response = await this.$store.dispatch('detail/delete', {
        url: '/' + this.componentCategory + '/detail?id=' + JSON.stringify([this.value.requestParams.id])
      })
      if (response) {
        this.$emit('detail-delete')
      } else {
        this.checkingDelete = false
      }
      return true
    },
    async checkSafeDelete () {
      if (this.value.requestParams.id) {
        this.loading = true
        this.canDelete = await this.$store.dispatch('detail/checkSafeDelete', {
          url: '/' + this.componentCategory + '/check-delete?id=' + this.value.requestParams.id
        })
        this.loading = false
      }
    }
  },
  async mounted () {
    return this.fillDetails()
  }
}
