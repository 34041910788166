<template>
  <v-select
    :value="value"
    @input="$emit('input', $event)"
    :items="agentsWithEmpty"
    item-value="id"
    item-text="value"
    label="Ответственный агент"
    :disabled="!$can('admin')"
  ></v-select>
</template>

<script>
export default {
  name: 'AgentSelect',
  props: {
    value: Number
  },
  data () {
    return {
      isLoading: false,
      agentList: []
    }
  },
  computed: {
    agentsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('users.agents', true, false)
    }
  }
}
</script>

<style scoped>

</style>
