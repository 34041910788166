import Advert from '@/components/structures/advert'
import Building from '@/components/structures/building'
import Street from '@/components/structures/street'
import Phone from '@/components/structures/phone'
import Agent from '@/components/structures/agent'
import CmsPage from '@/components/structures/cms-page'

class StructureFactory {
  constructor (name) {
    switch (name) {
      case 'advert':
        return new Advert()
      case 'building':
        return new Building()
      case 'street' :
        return [new Street()]
      case 'phone' :
        return new Phone()
      case 'user' :
        return new Agent()
      case 'cms-page' :
        return new CmsPage()
    }
  }
}

export default StructureFactory
