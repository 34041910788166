<template>
  <div>
    <v-select
      label="Роль"
      class="pa-2"
      :value="userRole"
      @input="$emit('update:userRole', $event)"
      :items="userRoleItems"
      item-value="name"
      item-text="description"
      :disabled="!$can('admin')"
    ></v-select>
    <v-menu
      :close-on-content-click="false"
      close-on-click
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          :disabled="userRole !== 'agent' || !$can('admin')"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-list
        flat
        subheader
      >
        <v-subheader>Индивидуальные разрешения</v-subheader>
        <v-list-item-group
          v-model="settings"
          multiple
        >
          <v-list-item
            v-for="(permission, index) in customPermissionsItems"
            :key="permission.name"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{permission.description}}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import userComputed from '@/components/mixins/userComputed'

export default {
  mixins: [userComputed],
  name: 'UserRole',
  props: {
    userRole: String,
    customPermissions: Array
  },
  data () {
    return {
      settings: []
    }
  },
  watch: {
    customPermissionsItems: {
      handler (val) {
        if (val) {
          this.permissionsToSettings()
        }
      },
      immediate: true,
      deep: true
    },
    customPermissions: {
      handler () {
        if (!this.customPermissions.length || this.settings.length === this.customPermissions.length) {
          return
        }
        this.permissionsToSettings()
      },
      deep: true
    },
    settings: {
      handler () {
        if (this.settings.length === this.customPermissions.length) {
          return
        }
        let result = []
        this.settings.forEach(item => {
          result.push(this.customPermissionsItems[item].name)
        })
        this.$emit('update:customPermissions', result)
      },
      deep: true
    }
  },
  methods: {
    handleCustomPermissions (data) {
      this.$emit('update:customPermissions', data)
    },
    permissionsToSettings () {
      this.customPermissions.forEach(userPermission => {
        const index = this.customPermissionsItems.findIndex(permission => permission.name === userPermission)
        if (index >= 0) {
          this.settings.push(index)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
