<template>
  <div>
    <v-alert
      border="top"
      color="red lighten-2"
      dark
      v-if="checkErrors('detail.userPhones').length"
    >
      {{checkErrors('detail.userPhones')[0]}}
    </v-alert>
    <div
      v-for="(phone, index) in localPhones"
      :key="index+'-'+localPhones.length+phone.sort_order"
    >
      <PhoneInput
        :value="phone"
        :index="index"
        @input="phone = $event"
        :can-remove="localPhones.length > 1"
        :mask="phoneMask"
        @delete="handleRemovePhone(index)"
        @search="handleSearchAdvertsByPhone(phone)"
        input-class="pl-3"
        phone-cols="9"
      />
    </div>
    <v-row>
      <v-col cols="12">
        <v-btn
          class="ml-2 mb-2"
          small
          @click="handleAddPhone"
        >
          <v-icon>mdi-plus</v-icon>
          Добавить номер
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PhoneInput from '@/components/views/admin/user/Detail/PhoneList/PhoneInput'
import UserPhone from '@/components/structures/userPhone'
import validationErrors from '@/components/mixins/validationErrors'
import phoneComputed from '@/components/mixins/phoneComputed'
import * as Config from '@/config'

export default {
  mixins: [validationErrors, phoneComputed],
  name: 'PhoneList',
  components: { PhoneInput },
  props: {
    value: Array
  },
  data () {
    return {
      localPhones: this.value,
      phoneToSearch: []
    }
  },
  inject: ['$v'],
  watch: {
    value: {
      handler () {
        this.localPhones = this.value
      },
      deep: true
    }
  },
  methods: {
    handleAddPhone () {
      this.localPhones.push(new UserPhone())
    },
    handleRemovePhone (index) {
      this.localPhones.splice(index, 1)
    },
    handleSearchAdvertsByPhone (phone) {
      this.phoneToSearch = [phone]
    }
  },
  computed: {
    phoneMask () {
      return Config.phoneMask
    }
  }
}
</script>

<style scoped>

</style>
