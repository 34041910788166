<template>
  <v-row class="d-flex align-center">
    <v-col :cols="phoneCols">
      <v-text-field
        label="телефон"
        :class="inputClass"
        :value="maskedPhone"
        @input="handleInput($event)"
        v-mask="{mask: mask, unmaskedVar: 'clearValue'}"
        :error-messages="checkErrors()"
      ></v-text-field>
    </v-col>
    <v-col
      cols="2"
    >
      <v-btn
        v-if="canRemove"
        color="primary"
        icon
        @click="$emit('delete')"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        :disabled="!canSearch"
        @click="$emit('search')"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from '@titou10/v-mask'

export default {
  name: 'PhoneInput',
  directives: {
    mask
  },
  props: {
    value: Object,
    canRemove: Boolean,
    mask: String,
    inputClass: String,
    phoneCols: String,
    index: Number
  },
  data () {
    return {
      phone: this.value,
      maskedPhone: this.value.phone.phone,
      clearValue: null
    }
  },
  inject: ['$v'],
  computed: {
    canSearch () {
      return this.clearValue && this.clearValue.length === 11 && this.phone.id
    }
  },
  watch: {
    clearValue () {
      this.phone.phone.phone = this.clearValue
      this.$emit('change', this.phone)
    },
    value: {
      handler () {
        this.phone = this.value
      },
      deep: true
    }
  },
  methods: {
    checkErrors () {
      let errors = []
      const phone = this.$v.detail.userPhones.$each[this.index]
      if (phone) {
        if (phone.phone.phone.hasOwnProperty('minLength')) {
          !phone.phone.phone.minLength && errors.push(`Введите корректный номер телефона`)
        }
        if (phone.phone.phone.hasOwnProperty('required')) {
          !phone.phone.phone.required && errors.push('Поле должно быть заполнено')
        }
      }
      return errors
    },
    handleInput (data) {
      this.maskedPhone = data
    }
  }
}
</script>

<style scoped>

</style>
