class Street {
  constructor () {
    this.id = null
    this.name = null
    this.is_main = true
    this.parent_id = null
    this.is_verified = false
  }
}

export default Street
