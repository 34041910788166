import Street from '@/components/structures/street'

class Address {
  constructor () {
    this.id = null
    this.building_number = ''
    this.is_main = true
    this.street = new Street()
  }
}

export default Address
