import UserPhone from '@/components/structures/userPhone'

class Agent {
  constructor () {
    this.id = null
    this.username = ''
    this.email = ''
    this.birth_date = null
    this.login = ''
    this.parent_id = null
    this.description = null
    this.userPhones = [new UserPhone()]
    this.avatar = null
    this.customPermissions = []
    this.role = 'agent'
  }
}

export default Agent
