<template>
  <div>
    <DatePicker
      :value="birthDate"
      @input="$emit('update:birthDate', $event)"
      label="Дата рождения"
    />
    <v-select
      :value="districtId"
      @input="$emit('update:districtId', $event)"
      :items="districtCustomItemsWithEmpty"
      item-value="id"
      item-text="value"
      label="Район"
    ></v-select>
    <v-text-field
      :value="address"
      @input="$emit('update:address', $event)"
      label="Адрес"
    ></v-text-field>
  </div>
</template>

<script>
import DatePicker from '@/components/views/admin/common/DatePicker'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'

export default {
  mixins: [serviceDataComputeds],
  name: 'AgentData',
  components: { DatePicker },
  props: {
    birthDate: Number,
    districtId: Number,
    address: String
  }
}
</script>

<style scoped>

</style>
