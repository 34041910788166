import AdvertFilterList from '@/components/structures/filters/advertFilterList'

class CmsPage {
  constructor () {
    this.id = null
    this.header = ''
    this.url = ''
    this.title = ''
    this.meta_keywords = ''
    this.meta_description = ''
    this.content = ''
    this.is_published = 1
    this.published_at = null
    this.compilation = new AdvertFilterList()
  }
}

export default CmsPage
