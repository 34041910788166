export default {
  computed: {
    activeTab: {
      get: function () {
        return this.$store.getters['tab/getActiveTab'](this.categoryName)
      },
      set: function (tabIndex) {
        this.$store.commit('tab/setActiveTab', { categoryName: this.categoryName, tabIndex })
      }
    },
    categoryName () {
      return this.$route.name
    }
  },
  methods: {
    add (categoryName, type, data) {
      this.$store.commit('tab/add', { categoryName, type, data })
    },
    update (categoryName, tabIndex, tabData) {
      this.$store.commit('tab/update', {
        categoryName,
        tabIndex,
        data: tabData
      })
    },
    remove (categoryName, tabIndex) {
      this.$store.commit('tab/remove', { categoryName, tabIndex })
    }
  }
}
