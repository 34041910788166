import Address from '@/components/structures/address'

class Building {
  constructor () {
    this.id = null
    this.builder = null
    this.district_custom = null
    this.district_official = null
    this.building_material = null
    this.build_overlap = null
    this.build_year = null
    this.building_type = null
    this.total_floors = null
    this.subdistrict = null
    this.total_apartments = null
    this.building_serial = null
    this.front_doors_count = null
    this.overhaul = null
    this.apartment_complex = null
    this.manager_company = null
    this.description_service = null
    this.description_entrance = null
    this.is_verified = false
    this.is_ready = true
    this.mainBuildingAddress = new Address()
    this.buildingAddresses = [new Address()]
    this.buildingImages = []
    this.created_at = Math.floor(Date.now() / 1000)
  }
}

export default Building
