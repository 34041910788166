<template>
  <div>
    <div
      class="text-center"
      v-if="isLoading"
    >
      <v-progress-circular
        class="text-center"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!isLoading">
      <v-card outlined>
        <p class="on-air">{{detail.is_online ? 'В сети' : 'Не в сети'}}</p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="10"
                class="d-flex align-center"
              >
                <v-avatar color="blue">
                  <v-img
                    v-if="detail.avatar"
                    :src="axios.defaults.baseURL + '/cache/images/thumbnails/' + avatar.image.path + avatar.image.file_name"
                  ></v-img>
                  <v-icon
                    v-else
                    dark
                  >
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
                <UserRole
                  class="mr-5 d-flex align-center"
                  :user-role.sync="detail.role"
                  :custom-permissions.sync="detail.customPermissions"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="detail.username"
                  label="Имя"
                  class="pa-2"
                  :error-messages="checkErrors('detail.username')"
                ></v-text-field>
                <v-text-field
                  v-model="detail.email"
                  @focus="$v.detail.email.$reset()"
                  label="Почта"
                  class="pa-2"
                  :error-messages="checkErrors('detail.email')"
                ></v-text-field>
                <PhoneList
                  v-model="detail.userPhones"
                  @detail="handleOpenAdvert($event)"
                />
                <v-textarea
                  outlined
                  v-model="detail.description"
                  label="Доп информация"
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <AgentSelect
                  v-if="detail.role === 'customer' || detail.role === 'vip'"
                  v-model="detail.parent_id"
                />
                <AgentData
                  v-if="detail.role === 'admin' || detail.role === 'agent'"
                  :birth-date.sync="detail.birth_date"
                  :district-id.sync="detail.district_id"
                  :address.sync="detail.address"
                />
              </v-col>
            </v-row>
          </v-container>
          <div class="text-right saveBlock">
            <v-btn
              class="primary mr-2"
              small
              :loading="isSavingData"
              @click="saveDetail(false)"
            > Сохранить
            </v-btn>
            <v-btn
              small
              @click.stop="confirmDelete = true"
            > Удалить пользователя
              <v-icon>mdi-</v-icon>
            </v-btn>
            <v-dialog
              v-model="confirmDelete"
              max-width="390"
            >
              <v-card
                outlined
                class="pa-5"
              >
                <v-card-title class="headline">Подтвердите удаление</v-card-title>
                <v-card-text>
                  Вы действительно хотите удалить пользователя ?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    @click="confirmDelete = false"
                  >
                    Отменить
                  </v-btn>
                  <v-btn
                    class="primary"
                    small
                    @click="deleteDetail"
                    :loading="checkingDelete"
                  >
                    Да, удалить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import detail from '@/components/mixins/detail'
import UserRole from '@/components/views/admin/user/Detail/UserRole'
import { email, minLength, required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import PhoneList from '@/components/views/admin/user/Detail/PhoneList'
import AgentSelect from '@/components/views/admin/user/Detail/AgentSelect'
import AgentData from '@/components/views/admin/user/Detail/AgentData'
import tabActions from '@/components/mixins/tabActions'

export default {
  mixins: [detail, validationErrors, tabActions],
  components: { AgentData, AgentSelect, PhoneList, UserRole },
  name: 'Detail',
  data: () => ({
    confirmDelete: false,
    componentCategory: 'user'
  }),
  provide () {
    return {
      $v: this.$v
    }
  },
  validations: {
    detail: {
      username: {
        required
      },
      email: {
        email,
        isUnique (value) {
          let result = true
          if (this.$v.detail.email.$dirty && value) {
            result = this.$store.dispatch('auth/isUnique', {
              data: {
                email: value,
                id: this.detail.id
              }
            })
          }
          return result
        }
      },
      userPhones: {
        $each: {
          phone: {
            phone: {
              required,
              minLength: minLength(11)
            }
          }
        }
      }
    }
  },
  methods: {
    async hasErrors () {
      let canSave = false
      this.$v.$touch()
      await this.checkPending()
      if (this.$v.$anyError) {
        canSave = 'Введите корректные данные'
      } else {
        this.$v.$reset()
      }
      return canSave
    },
    handleOpenAdvert (data) {
      this.add('advert', 'detail', { requestParams: { id: data.id } })
      this.$router.push({ name: 'advert' })
    },
    /*add (categoryName, type, requestParams) {
      this.$store.commit('tab/add', { categoryName, type, data })
    },*/
    handleDelete () {
      this.confirmDelete = false
      this.$emit('delete')
    }
  }
}
</script>

<style scoped>
  .on-air {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #999999;
  }
</style>
